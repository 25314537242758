import React from 'react'
import { createContext,useState } from 'react';
import { ethers } from 'ethers';

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const[address,setAddress]=useState('');
    const[balance,setBalance]=useState('');
    const connectWallet=async ()=>{
        await window.ethereum.request({method:"eth_requestAccounts"}) // using this method we will authenticate our metamask to with our project
        const provider=new ethers.providers.Web3Provider(window.ethereum,"any");
        console.log(provider)
        if(provider.network!='goerli')
        {
            // await window.ethereum.request({
            //     method:'wallet_addEthereumChain',
            //     params:[
            //         {
            //             ...networks['goerli']
            //         }
            //     ]
            // })
            const account=provider.getSigner(); // getsigner use for the ethers
            const Address= await account.getAddress();
            setAddress(Address);
            const Balance=ethers.utils.formatEther(await account.getBalance());
            setBalance(Balance);
            console.log("balance:",Balance);
            console.log("address:",Address);

        }
       
    }
    return (
      <AuthContext.Provider value={{connectWallet,address}}>
        {children}
      </AuthContext.Provider>
    );
  };


export {AuthContext,AuthProvider};