import { useState } from "react";
import $ from "jquery";

function MysteryBox() {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick() {
    // Animate the box opening
    $("#mystery-box").animate(
      {
        width: "100%",
        height: "100%",
      },
      500,
      function () {
        // After the animation is complete, show the card
        $("#card").show().animate(
          {
            opacity: 1,
            translateX: 0,
          },
          500
        );
        setIsOpen(true);
      }
    );
  }

  return (
    <div className="flex justify-center">
      <div
        id="mystery-box"
        className={`w-64 h-64 bg-yellow-500 rounded-lg overflow-hidden relative ${
          isOpen ? "w-full h-full" : ""
        }`}
      >
        <div
          id="card"
          className="w-full h-full bg-white absolute top-0 left-0 opacity-0 transform translate-x-full p-6"
        >
          <h2 className="text-gray-800 font-bold text-lg mb-2">Card Title</h2>
          <p className="text-gray-700 text-sm">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            varius metus sit amet felis tristique faucibus.
          </p>
        </div>
      </div>
      <button
        id="open-box-btn"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleClick}
      >
        Open Mystery Box
      </button>
    </div>
  );
}

export default MysteryBox;
