import React, { useContext, useState } from "react";
import logo from "../assets/logo.png";
import { FaYinYang } from "react-icons/fa";
// import {Link} from 'react-router-dom';
import { BsDisplay } from "react-icons/bs";
import { SiMarketo } from "react-icons/si";
import { AiOutlineClose, AiOutlineMenu, AiFillHome } from "react-icons/ai";
import {FiShoppingCart} from 'react-icons/fi'
import ModalLayout from "./Modals/ModalLayout";
import { Link, useNavigate } from "react-router-dom";
import { InstanceContext } from "../context/InstanceContext";
import AirDropModal from "./Modals/AirDropModal";
const Navbar = () => {
  // useContext
  const {settoken}=useContext(InstanceContext);
  const [AirDropModalOpen, setAirDropModalOpen] = useState(false);
const navigate=useNavigate();
  // useState
  const [nav, setNav] = useState(false);
  // handle navbar
  const handleNav = () => {
    setNav(!nav);
  };
  // log out 
  const logout=()=>{
    localStorage.removeItem('tokenId');
    settoken("")
    navigate('/login');
}
  return (
    <>
      <div className=" w-full md:h-auto lg:h-[55px] xl:h-auto  bg-gradient-to-tl from-[#2f1f56] to-[#3E0049]  ">
        <div className="w-full  flex  lg:flex-row md:flex-col  mx-auto px-4 justify-between  items-center h-full">
          <div className="mt-2">
            <a href="/">
              <img src={logo} width="80px" height="52px" className="" />
            </a>
          </div>
          <div className="hidden md:flex  ">
            <ul className="flex md:my-3 md:justify-evenly md:items-center md:flex-wrap text-white text-center ">
              <li className="px-3 inline-flex items-center text-sm cursor-pointer font-bold ">
                {/* <FaYinYang className="mx-1 text-xl" /> */}
                <Link to="/"> The Shop </Link>
              </li>
              <li className="px-3 inline-flex items-center text-sm cursor-pointer font-bold ">
                {/* <BsDisplay className="mx-1 text-xl" /> */}
                <Link to="/marketplace"> Marketplace</Link>
              </li>
              {/* <li className="px-3 inline-flex items-center text-sm cursor-pointer font-bold ">
                
                Spin the Wheel 
              </li> */}
              <li className="px-3 inline-flex items-center text-sm cursor-pointer font-bold ">
                {/* <BsDisplay className="mx-1 text-xl" /> */}
                <Link to="/mint"> Mint </Link>
              </li>
            </ul>
          </div>
          <div className="inline-flex  ">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setAirDropModalOpen(true);
              }}
              className="hidden md:block bg-[#1891c9] p-2 rounded-xl items-center font-semibold text-white cursor-pointer  mr-4 hover:bg-[#dfb3c1]"
            >
              {" "}
              Air Drop{" "}
            </button>
            <button className="hidden md:block bg-[#A51240] p-2 rounded-xl items-center font-semibold text-white cursor-pointer  mr-4 hover:bg-[#8f002d]">
              {" "}
              play now{" "}
            </button>
            <button className="hidden md:block bg-[#193858] hover:bg-[#122c46] p-2 rounded-xl items-center font-semibold text-white cursor-pointer  mr-4 ">
              <Link to="/register">Sign up</Link>
            </button>

            <button
              className="hidden md:block text-white cursor-pointer p-2 rounded-xl items-center font-semibold hover:underline "
              onClick={logout}
            >
              log out
            </button>
          </div>
          <div onClick={handleNav} className="block md:hidden  ">
            {nav ? (
              <AiOutlineClose size={30} className="text-white  " />
            ) : (
              <AiOutlineMenu size={30} className=" text-white " />
            )}
          </div>
          {/* mobile view  */}
          <div
            className={
              nav
                ? "md:hidden  flex flex-col  left-1/4 inset-0 fixed    text-white  bg-[#2A2C2E] "
                : " hidden"
            }
          >
            <ul className="">
              <li className=" left-0 pt-4 px-4 flex justify-between  text-sm cursor-pointer ">
                <a href="https://Joat World.com/">
                  <img src={logo} className="h-10" />
                </a>
                {nav ? (
                  <AiOutlineClose
                    size={30}
                    className=" text-white  "
                    onClick={handleNav}
                  />
                ) : (
                  ""
                )}
              </li>
              <li className="bg-gray-300 mt-3 w-full h-[1px]"></li>
              <li className="px-4 pt-4  text-sm cursor-pointer ">
                {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
                <Link to="/"> The Shop</Link>
              </li>
              <li className="bg-gray-300 mt-3 w-full h-[1px]"></li>

              <li className="px-4 pt-4  text-sm cursor-pointer ">
                {/* <SiMarketo className="w-4 inline-flex h-4 mr-1" />  */}
                <Link to="/marketplace"> Marketplace</Link>
              </li>
              <li className="bg-gray-300 mt-3 w-full h-[1px]"></li>

              <li className="px-4 pt-4  text-sm cursor-pointer ">
                {/* <SiMarketo className="w-4 inline-flex h-4 mr-1" />  */}
                <Link to="/mint"> Mint </Link>
              </li>
              <li className="bg-gray-300 mt-3 w-full h-[1px] "></li>
              {/* <li className="px-4 pt-4  text-sm cursor-pointer bg-[#000000]">
              <button className="  bg-[#A51240] p-2 rounded-xl items-center font-semibold text-white cursor-pointer  mr-4 hover:bg-[#8f002d]">
              {" "}
              play now{" "}
            </button>
              </li> */}

              {/* <li className="bg-gray-300 mt-3 w-full h-[1px] "></li> */}
            </ul>
            <div className="  mt-auto bg-gray-300  w-full h-[1px]"></div>
            <div className="flex pb-4">
              <div className="bg-[#222326]  w-[70%] p-4 flex justify-center ">
                <button className="  bg-blue-600 p-4 rounded-xl items-center font-extrabold text-white cursor-pointer  mr-4 hover:bg-[#8f002d]">
                  <Link to="/register"> Sign up </Link>
                </button>
              </div>
              <div className="bg-[#2f2f33] w-[30%] flex justify-center pb-4">
                <button
                  className="   p-4 rounded-xl items-center font-extrabold text-white cursor-pointer hover:underline mr-4 "
                  onClick={logout}
                >
                  {" "}
                  log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLayout
        id="airdrop-modal"
        modalOpen={AirDropModalOpen}
        setModalOpen={setAirDropModalOpen}
        title="Send airdrop"
      >
        <div className="flex flex-col w-full">
          <AirDropModal setAirDropModalOpen={setAirDropModalOpen} />
        </div>
      </ModalLayout>
    </>
  );
};

export default Navbar;
