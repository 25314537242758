import { useState,useEffect } from "react"
import { useNavigate } from "react-router-dom"
const Protected = (props) => {
    const {Component}=props;
    const navigate=useNavigate();
    useEffect(()=>{
        let login=localStorage.getItem('tokenId');
        // console.log("token ID from protected route",login);
        if(!login)
        {
            navigate('/login');
        }
        
    },[]);
    console.log("component:",Component);
  return (
   <>
    <Component/>
   </>
  )
}

export default Protected