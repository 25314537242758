import React, { useContext, useState } from "react";
import { Link,useNavigate } from 'react-router-dom'
import {InstanceContext} from '../../../context/InstanceContext'
const Navbar = () => {
    // useContext
    const {settoken}=useContext(InstanceContext);
    const navigate=useNavigate();
     // log out 
  const logout=()=>{
    localStorage.removeItem('tokenId');
    settoken("")
    navigate('/login');
}
  return (
    <>
      <div className=' hidden md:block lg:block xl:block ml-[10%] w-[90%]     mt-12'>
        <div className='flex justify-between'>
     <h1 className='text-white text-3xl font-extrabold'>MARKETPLACE <span className='font-bold text-lg'> <br></br>BETA</span> </h1>
    <div className='inline-flex pr-4'>
            <button className=" text-white cursor-pointer p-2 h-10 rounded-xl items-center font-bold hover:underline mr-4  "onClick={logout}>
              Log Out  
            </button>
            <button className=" bg-[#cc3044] hover:bg-[#c74d5e]  rounded-xl items-center font-bold text-white cursor-pointer px-6 p-0 h-10  ">
              {" "}
              <Link to='/register'>
              sign up{" "}
              </Link>
            </button>
            </div>
            </div>
    </div>
    </>
  )
}

export default Navbar