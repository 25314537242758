import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import pic1 from "../../assets/marketplace/Left.png";
import pic2 from "../../assets/marketplace/3.png";
import pic3 from "../../assets/marketplace/4.png";
import pic4 from "../../assets/marketplace/5.png";
import pic5 from "../../assets/marketplace/6.png";
import { AiOutlineClose, AiOutlineSortAscending } from "react-icons/ai";
import { TbSortDescendingLetters } from "react-icons/tb";
import ReactSlider from "react-slider";
import { Range } from "react-range";
import Footer from "./components/Footer";
import Cards from "./components/Cards";
import { Scrollbars } from 'react-custom-scrollbars';

const MarketPlace = () => {
  const [values, setValues] = useState([0]);
  const [values2, setvalues2] = useState([1000]);
  const [mintSlider, setMintslider] = useState(0);
  const [tolMintSlider, setTolmintslider] = useState(0);
  const[level,setLevel]=useState(0);
  const [filter, setfilter] = useState(true);
  const [sort, setSort] = useState(false);
  const [filterSidebar, setFilterSiderbar] = useState(false);
  const[showMore,setShow]=useState(false);
  const[showSkill,setShowSkill]=useState(true);
  const[showArtists,setArtists]=useState(true);
  const filterHandler = () => {
    setfilter(!filter);
  };
  const SortHandler = () => {
    setSort(!sort);
  };
  const filterSidebarHandler = () => {
    setFilterSiderbar(!filterSidebar);
  };
const showHandlar=()=>{
  setShow(!showMore);
}
const ShowSkillHandler=()=>{
  setShowSkill(!showSkill);
}
const ShowArtistsandler=()=>{
  setArtists(!showArtists);
}
  return (
    <>
      <div className=" flex flex-col bg-black w-full  h-auto pb-8 ">
        <Sidebar />
        {/* navbar */}
        <Navbar />
        {/* headre  */}
        <div className=" mt-16 md:block hidden ml-[10%] w-[90%]    ">
          <h1 className=" font-extrabold text-7xl text-white">MARKETPLACE</h1>
        </div>
        {/* filters bar */}
        <div className="flex justify-between ml-[10%] w-[90%]   pr-4 mt-8">
          <div
            onClick={filterHandler}
            className={`bg-[#4F5364] hover:bg-[#5d6174] md:block lg:block xl:block hidden p-2  font-bold text-white cursor-pointer`}
          >
            close filters
          </div>
          <div
            onClick={filterSidebarHandler}
            className={`bg-[#4F5364] hover:bg-[#5d6174] p-2 block md:hidden lg:hidden xl:hidden  font-bold text-white cursor-pointer`}
          >
            close filters
          </div>
          {/* responsive filter menu */}
          <div
            className={
              filterSidebar
                ? "md:hidden  flex flex-col  right-1/3 inset-0 fixed    text-white  bg-[#000105] "
                : " hidden"
            }
          >
            <Scrollbars  style={{ width:"100%", height:"100%" }}>

            <ul className="pr-3 pb-8">
              <li className="flex justify-end p-3">
                {filterSidebar ? (
                  <AiOutlineClose
                    onClick={filterSidebarHandler}
                    size={30}
                    className="text-white font-extrabold"
                  />
                ) : (
                  ""
                )}
              </li>
              <li className="bg-[#d3ac4a]  w-full h-[1px]"></li>
              <li className="flex    mt-8 text-white pl-4">
                <div className="font-extrabold text-xl ">ITEM NAME</div>
              </li>
              <li className=" flex mt-1 pl-4 pr-4   text-white">
                <input
                  type="text"
                  className="pt-4 w-full  bg-[#4F5364] font-semibold "
                  placeholder="Search(e.g. Altitude)"
                />
              </li>
              <li className="bg-gray-300  w-full my-3 h-[1px]"></li>
              <li className="text-white mt-2 pl-4">
                <p className="text-lg font-bold">Price</p>
              </li>
              <li className="bg-gray-300  w-full my-3 h-[1px]"></li>
              <li className="flex w-full justify-between  mt-4 text-white p-4 pt-0 pb-0">
                <div className="flex justify-around bg-[#4F5364] p-2 w-[45%] font-semibold">
                  <p className="text-lg ">$</p>
                  <p className="text-lg ">0</p>
                </div>
                <div className="flex justify-around bg-[#4F5364] p-2 w-[45%] font-semibold">
                  <p className="text-lg ">$</p>
                  <p className="text-lg ">1000</p>
                </div>
              </li>
              <li className="bg-gray-300  w-full my-3 h-[1px]"></li>
              <li className="flex justify-between   text-white font-bold p-4 pt-0 pb-0">
                <p className="text-lg ">For Sale</p>
                <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              </li>
              <li className="flex justify-between  my-4 text-white font-bold p-4 pt-0 pb-0">
                <p className="text-lg ">Owned By Me</p>
                <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              </li>
              <li className="h-1  bg-[#4F5364]  "></li>
              <li className="flex justify-between  my-4 text-white font-bold pl-4">
                <p className="text-lg ">Mint #</p>
              </li>
              <li className="text-white pl-2 pr-2 ">
                <ReactSlider
                  step={1}
                  min={0}
                  max={3000}
                  className={`w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab`}
                  thumbClassName="absolute w-3 h-4 cursor-grab bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                  value={mintSlider}
                  onChange={(mintSlider) => {
                    setMintslider(mintSlider);
                  }}
                />
                <span>{mintSlider}</span>
              </li>

              <li className="flex justify-between  my-4 pl-4 text-white font-bold">
                <p className="text-lg ">TOTAL MINTED</p>
              </li>
              <li className="text-white pl-2 pr-2 ">
                <ReactSlider
                  step={1}
                  min={0}
                  max={3000}
                  className={`w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab`}
                  thumbClassName="absolute w-3 h-4 cursor-grab bg-indigo-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                  value={tolMintSlider}
                  onChange={(tolMintSlider) => {
                    setTolmintslider(tolMintSlider);
                  }}
                />
                <span>{tolMintSlider}</span>
              </li>
              <li className="h-1  bg-[#4F5364] my-4 "></li>
              <li className="flex justify-between pl-4  text-white font-bold">
                <p className="text-lg ">ITEM</p>
              </li>
              <li className="flex items-center   text-white font-bold p-4 pl-0 pr-0">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Accessories</p>
              </li>
              <li className="flex items-center text-white font-bold p-4 pl-0 pr-0">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Accessories</p>
              </li>
              <li className="h-1  bg-[#4F5364] my-4 "></li>
              <li className="flex justify-between pl-4 pr-4  text-white font-bold">
                <p className="text-lg ">Boxed Only</p>
                <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              </li>
              <li className="flex justify-between  my-4 pl-4 pr-4 text-white font-bold">
                <p className="text-lg ">MashUP Only</p>
                <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
              </li>
              <li className="h-1  bg-[#4F5364]  "></li>
              <li className="flex justify-between pl-4 my-4 text-white font-bold">
                <p className="text-lg ">SEASON</p>
              </li>
              <li className="flex  items-center  pl-4 p-4 pt-0 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Private Beta</p>
              </li>
              <li className="flex  items-center  mt-0 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Pre-Alpha</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Early Access</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Open Beta</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Season 1</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Alpha</p>
              </li>
              <li className="h-1  bg-[#4F5364] my-4 "></li>
              <li className="flex justify-between pl-4 my-4 text-white font-bold">
                <p className="text-lg ">GRADE</p>
              </li>
              <li className="flex  items-center  pl-4 p-4 pt-0 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Fair</p>
              </li>
              <li className="flex  items-center  mt-0 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Good</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Excellent</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Mint</p>
              </li>
              <li className="flex  items-center mt-4 pl-4 pr-4 text-white font-bold">
                <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
                <p className="text-xl  ">Gem Mint</p>
              </li>
              <li className="h-1  bg-[#4F5364] my-4 "></li>
              <li className="flex justify-between pl-4 my-4 text-white font-bold">
                <p className="text-lg ">Level</p> 
              </li>
              <li className="  text-white pl-2 pr-2  ">
              <ReactSlider
                step={1}
                min={0}
                max={15}
                className={`w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab`}
                thumbClassName="absolute w-3 h-4 cursor-grab bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                value={level}
                onChange={(level) => {
                  setLevel(level);
                }}
              />
              <span className="text-white">{level}</span>
            </li>
            <li className="h-1 bg-[#4F5364] my-4"></li>
            <li className="flex justify-between  pl-4  text-white font-bold">
              <p className="text-lg ">SKILLS</p>
              <p onClick={ShowSkillHandler} className="text-xl font-extrabold cursor-pointer pr-4">_</p>
            </li>
            {showSkill?
            <>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Bruiser</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Tanker</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Tracer</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Trickster</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Wrecker</p>
            </div>
            </>:''
}
<div className="h-1  bg-[#4F5364] my-4 "></div>
<div className="flex justify-between  mt-4 ml-4 text-white font-bold">
              <p className="text-lg ">ARTISTS</p>
              <p onClick={ShowArtistsandler} className="text-xl pr-4 font-extrabold cursor-pointer">_</p>
            </div>
            {showArtists?<>
            <div className=" flex items-center pl-4  mt-4 text-white font-bold">
                <input
                  type="text"
                  className="pt-2 w-full  bg-[#4F5364] font-semibold "
                  placeholder="Search"
                />
              </div>
              <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Michael Lau</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Martin Hsu</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">I Like You You're Werid (ILYYW)</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Riiisa Boogie</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Burberry</p>
            </div>

              </>:''}
            </ul>
           </Scrollbars>

          </div>
          <div className="flex justify-around items-center">
            <div className="hidden md:block">
              <div className=" bg-[#4F5364] hover:bg-[#5d6174] p-2 mr-2  font-bold text-white inline-flex cursor-pointer text-xl">
                <AiOutlineArrowUp className="font-bold h-6 w-8" />$
              </div>
              <div className="bg-[#4F5364] hover:bg-[#5d6174] p-2 mr-2  font-bold text-white inline-flex cursor-pointer text-xl">
                <AiOutlineArrowDown className="font-bold h-6 w-8" />$
              </div>
            </div>
            <div
              onClick={SortHandler}
              className="bg-[#4F5364] hover:bg-[#5d6174] p-2  font-bold text-white inline-flex cursor-pointer text-xl"
            >
              Sort
              <IoIosArrowDown
                className={`${sort && "rotate-180"} font-bold h-6 w-8`}
              />
            </div>
            <div
              className={`${sort ? "block relative text-white " : "hidden"} `}
            >
              <div className="absolute top-[22px] w-64 h-20 -left-64 bg-[#363A49]">
                <div className="pt-2 flex cursor-pointer hover:bg-[#A2A4A8]">
                  <AiOutlineSortAscending className="w-8 h-7 " />
                  <p className="text-lg font-bold pl-2 ">
                    Alphabetical(Ascending)
                  </p>
                </div>
                <div className="pt-2 flex cursor-pointer hover:bg-[#A2A4A8]">
                  <TbSortDescendingLetters className="w-8 h-7 " />
                  <p className="text-lg font-bold pl-2 ">
                    Alphabetical(Descending)
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden text-white">
              <div>usman </div>
            </div>
          </div>
        </div>

        {/* <Filters/> */}
        <div className="flex flex-row w-[90%]  lg:ml-[10%] xl:ml-[10%] md:ml-[10%]  ml-4 ">
          <div
            className={
              filter
                ? ` flex-col    w-[30%]  md:block lg:block xl:block hidden     origin-left duration-500 `
                : "hidden  origin-left duration-500"
            }
          >
            <div className="flex    mt-8 text-white ">
              <div className="font-extrabold text-xl ">ITEM NAME</div>
            </div>
            <div className=" flex mt-1   text-white">
              <input
                type="text"
                className="pt-4 w-full  bg-[#4F5364] font-semibold "
                placeholder="Search(e.g. Altitude)"
              />
            </div>
            <div className="h-1 flex  bg-[#4F5364] my-8 "></div>
            <div className="text-white mt-2">
              <p className="text-lg font-bold">Price</p>
            </div>
            <div className="text-white flex">
              <Range
                step={1}
                min={0}
                max={1000}
                values={values}
                onChange={(values) => {
                setValues(values);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    className="w-full h-2 pr-2 my-4 bg-white rounded-md"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    className="w-3 h-3 transform translate-x-10 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                )}
              />
              <Range
                step={1}
                min={0}
                max={1000}
                values={values2}
                onChange={(values2) => {
                  setvalues2(values2);
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    // className="w-full h-2 pr-2 my-4 bg-white rounded-md"
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    className="w-3 h-3 transform translate-x-10 bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                )}
              />
            </div>
            <div className="flex w-full justify-between  mt-4 text-white">
              <div className="flex justify-around bg-[#4F5364] p-2 w-[45%] font-semibold">
                <p className="text-lg ">$</p>
                <p className="text-lg ">{values[0]}</p>
              </div>
              <div className="flex justify-around bg-[#4F5364] p-2 w-[45%] font-semibold">
                <p className="text-lg ">$</p>
                <p className="text-lg ">{values2[0]}</p>
              </div>
            </div>
            <div className="h-1  bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">For Sale</p>
              <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">Owned By Me</p>
              <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div className="h-1  bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">Mint #</p>
            </div>
            <div className="  text-white">
              <ReactSlider
                step={1}
                min={0}
                max={3000}
                className={`w-full h-3 pr-2 my-4 bg-white rounded-md cursor-grab`}
                thumbClassName="absolute w-4 h-4 cursor-grab bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                value={mintSlider}
                onChange={(mintSlider) => {
                  setMintslider(mintSlider);
                }}
              />
              <span>{mintSlider}</span>
            </div>

            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">TOTAL MINTED</p>
            </div>
            <div className="    ">
              <ReactSlider
                step={1}
                min={0}
                max={3000}
                className={`w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab`}
                thumbClassName="absolute w-3 h-4 cursor-grab bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                value={tolMintSlider}
                onChange={(tolMintSlider) => {
                  setTolmintslider(tolMintSlider);
                }}
              />
              <span className="text-white">{tolMintSlider}</span>
            </div>
            <div className="h-1  bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">ITEM</p>
            </div>
            <div className="flex  items-center  mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Accessories</p>
            </div>
            <div className="flex  items-center  mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Accessories</p>
            </div>
            <div className="h-1 items-center bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">Boxed Only</p>
              <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">MashUP Only</p>
              <label class="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" />
                <div class="w-10 h-5  peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
            <div className="h-1  bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">SEASON</p>
            </div>
            <div className="flex  items-center  mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Private Beta</p>
            </div>
            <div className="flex  items-center  mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Pre-Alpha</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Early Access</p>
            </div>
            <div onClick={showHandlar} className={`${showMore?'hidden':'flex items-center  ml-4  mt-4 text-white font-bold'} `}>
              {/* <input className="text-lg p-2 mr-4 ml-4" type="checkbox" /> */}
              <p className="text-xl cursor-pointer text-[#B3B3B4] ">More</p>
            </div>
            {showMore?
            <>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Open Beta</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Season 1</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Alpha</p>
            </div>
            </>:''
}
            <div className="h-1  bg-[#4F5364] my-8 "></div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">GRADE</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Fair</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Good</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Excellent</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Mint</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Gem Mint</p>
            </div>
            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">LEVEL</p>
            </div>
            <div className="    ">
              <ReactSlider
                step={1}
                min={0}
                max={15}
                className={`w-full h-3 pr-2 my-4 bg-gray-200 rounded-md cursor-grab`}
                thumbClassName="absolute w-3 h-4 cursor-grab bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 -top-2px"
                value={level}
                onChange={(level) => {
                  setLevel(level);
                }}
              />
              <span className="text-white">{level}</span>
            </div>
            <div className="h-1  bg-[#4F5364] my-8 "></div>

            <div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">SKILLS</p>
              <p onClick={ShowSkillHandler} className="text-xl font-extrabold cursor-pointer">_</p>
            </div>
            {showSkill?
            <>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Bruiser</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Tanker</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Tracer</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Trickster</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Wrecker</p>
            </div>
            </>:''
}
<div className="h-1  bg-[#4F5364] my-8 "></div>
<div className="flex justify-between  mt-4 text-white font-bold">
              <p className="text-lg ">ARTISTS</p>
              <p onClick={ShowArtistsandler} className="text-xl font-extrabold cursor-pointer">_</p>
            </div>
            {showArtists?<>
            <div className=" flex items-center   mt-4 text-white font-bold">
                <input
                  type="text"
                  className="pt-2 w-full  bg-[#4F5364] font-semibold "
                  placeholder="Search"
                />
              </div>
              <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Michael Lau</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Martin Hsu</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">I Like You You're Werid (ILYYW)</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Riiisa Boogie</p>
            </div>
            <div className="flex items-center   mt-4 text-white font-bold">
              <input className="text-lg p-2 mr-4 ml-4" type="checkbox" />
              <p className="text-xl  ">Burberry</p>
            </div>

              </>:''}
          </div>
          {/* <Filters close/>  */}
          {/* cards */}
          <div className=" w-full h-auto  mt-2 pb-16  ">
            {/* grid */}
            <div
              className={`${
                filter
                  ? "lg:grid-cols-2 xl:grid-cols-2"
                  : "lg:grid-cols-3 xl:grid-cols-3"
              }  justify-center  grid sm:grid-cols-1 md:grid-cols-2   gap-y-8 lg:pl-16 xl:pl-16  md:pl-4 mt-8`}
            >
              {/* CARD */}
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic1} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
              {/* card 2 */}
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic2} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
              {/* card 3 */}
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic3} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
              {/* card 4 */}
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic4} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
              {/* card 5 */}
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic5} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <Footer />
    </>
  );
};
export default MarketPlace;
