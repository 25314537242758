import React, { useEffect, useState } from "react";
import img1 from "../assets/Left.png";
import img2 from "../assets/right.png";
import img3 from "../assets/actor3.png";
import { toast } from "react-toastify";
import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TiArrowForwardOutline } from "react-icons/ti";
import axios from "axios";
import { Link } from "react-router-dom";

const MenuCards = () => {
  const [getApiData, setGetApiData] = useState([]);
  useEffect(() => {
    const GetApiCall = async () => {
      const respo = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/GetData`);
      console.log("Get API response:", respo.data.blocks);
      setGetApiData(respo.data.blocks);
    };
    GetApiCall();
    console.log("GetApiData response :", getApiData);
  }, [0]);

  //* return random number between 1 to 10
  function random () 
  {return Math.floor(Math.random() * 10) + 1;};
  
  function getdaysDifference(endTime){
    // Create a Moment.js object for another datetime
    const anotherDate = moment(endTime);
    const now = moment();

    // Calculate the difference between the two datetimes in milliseconds
    const diffInMilliseconds = now.diff(anotherDate);

    // Convert the difference to a human-readable format
    const diff = moment.duration(diffInMilliseconds).humanize();
    console.log("diff:", diff);
    return diff;
  }


  return (
    <>
      <div className="bg-[#222326] w-full h-auto pb-16">
        <h1 className="text-white font-extrabold text-5xl pt-10 mx-10">
          MORE AVAILABLE
        </h1>
        {/* GRID */}
        <div className="justify-center  grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-2 gap-y-8 mx-10 mt-8">
          {/* CARD */}
          {getApiData.map((item, index) => (
            <div className="rounded-xl w-[300px] bg-[#15161B] p-6  ">
              {/*TIMER  */}
              <div className="flex flex-row justify-end -mt-4 -mx-4">
                {item.StartTime && item.EndTime != null ? (
                  <div className="flex-row items-center justify-self-center p-2 m-2  bg-[#a0eef8]  rounded-2xl text-black ">
                    <p className="font-bold text-sm">
                      <AiOutlineClockCircle className="text-purple-600 inline-flex  m" />{" "}
                      Ends in{" "}
                      <span className="text-red-700">
                        {/* check if item end date is greater than current date if true show time diffece using moment   */}
                        {/* {moment(item.StartTime).isBefore(moment()) && moment(item.EndTime).isAfter(moment())
                        ? moment(item.EndTime).fromNow()
                        : '0s'} */}

                        {/* {moment(item.StartTime).isBefore(moment()) && */}
                        {moment(item.EndTime).isAfter(moment())
                          ? getdaysDifference(item.EndTime)
                          : "0s"}

                        {/* {item.EndTime   
                        ? moment(item.EndTime).fromNow()
                        : "5d"} */}
                      </span>{" "}
                    </p>
                  </div>
                ) : null}
              </div>
              {/* TIMER CLOSE */}
              <Link
                to={`/detail/${item.image}/${item.Price}/${item.Name}/${
                  item.Description
                }/${
                  moment(item.EndTime).isAfter(moment())
                          ? getdaysDifference(item.EndTime)
                          : "0s"}
              `}
              >
                <div className="flex overflow-hidden">
                  <img
                    alt={item.Name}
                    className="hover:scale-110 h-[220px] cursor-pointer "
                    // src={item.image}
                    src={`${process.env.REACT_APP_GATEWAY_MORALIES_URL}/${item.image}`}
                    // src={`https://gateway.moralisipfs.com/ipfs/${item.image}`}
                  />
                </div>
              </Link>
              <div className="text-white ">
                <p className="font-bold">{item.Name}</p>
              </div>
              <div className="text-white flex justify-between pt-2">
                <p className="font-bold">{item.Price} starting</p>
                <button
                  className="p-2 w-full md:w-[40%] xl:w-[50%] lg:w-[50%]  bg-[#0E6B92] hover:bg-[#10475f] items-center justify-center  font-bold rounded-xl"
                  onClick={async () => {
                    const { Name, image, Price } = item;
                    const respo = await axios.post(
                      `${process.env.REACT_APP_BACKEND_URL}/AddToCart`,
                      { Name, image, Price }
                    );
                    console.log("Get API response:", respo.data.message);
                    toast.success(respo.data.message);
                  }}
                >
                  Buy Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MenuCards;
