import React, { useState } from "react";
import { ethers } from "ethers";
import { AIRDROP_ABI } from "../../utils/AIRDROP_ABI";
import { toast } from "react-toastify";
import axios from "axios";



const initialFormData ={
  address : "",
  nft: "",
}


//* method to send airdrop to a user
const sendAirdrop =  (address, nft) => {
  console.log("Airdrop in Process:", address, nft);
  console.log("Airdrop in Process:", process.env.REACT_APP_AIRDROP_CONTRACT_ADDRESS);
   const provider=new ethers.providers.Web3Provider(window.ethereum);
  const signer=provider.getSigner();
  const contract = new ethers.Contract(
    process.env.REACT_APP_AIRDROP_CONTRACT_ADDRESS,
    AIRDROP_ABI,
    signer
  );
   contract.airdrop([address], nft).then((transaction) => {
    toast.promise(
      transaction
        .wait()
        .then((tx) => {
          console.log("Airdrop in Process:", tx);
          toast.info(tx);
        })
        .catch((err) => {
          console.log("Error in Airdropping:", err);
          toast.error("Error in Airdropping:", err);
        }),
      {
        pending: "Airdrop in Process...",
        success: "Airdropped Successfully 👌",
        error: "Airdrop Failed 🤯",
      }
    );
  });

  
};

//* save airdrop to db
const SaveAirDroptoDB =  (formData) => {

  axios({
    method: "post",
    url: `${process.env.REACT_APP_BACKEND_URL}/addAirDrop`,
    data: formData,
  })
    .then((resp) => {
      console.log("API response:", resp);
    })
    .catch((err) => {
      console.log("Error from API", err.message);
    });
};

const AirDropModal = ({ setAirDropModalOpen }) => {
  const [formData, setFormData] = useState(initialFormData);

  //* handle input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  //* handle form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
    // * promise all settelled to send airdrop and save to db
    await Promise.allSettled([sendAirdrop(formData.address, formData.nft), SaveAirDroptoDB(formData)]);
    //  await sendAirdrop(formData.address, formData.nft);
    // await SaveAirDroptoDB(formData);
    setAirDropModalOpen(false);     
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="px-5 py-4">
          <div className="text-sm">
            <div className="font-medium text-slate-800 mb-3 text-center">
              Lets Airdrop NFT 🎁
            </div>
          </div>
          <div className="space-y-3">
            <div>
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="Address"
                >
                  Address <span className="text-rose-500">*</span>
                </label>

                <input
                  id="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="form-input w-full px-2 py-1 rounded-lg"
                  name="address"
                  type="text"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1" htmlFor="NFT">
                  NFT <span className="text-rose-500">*</span>
                </label>

                <input
                  id="nft"
                  value={formData.nft}
                  name="nft"
                  onChange={handleInputChange}
                  className="form-input w-full px-2 py-1 rounded-lg"
                  type="text"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        {/* Modal footer */}
        <div className="px-5 py-4 border-t border-slate-200">
          <div className="flex flex-wrap justify-end space-x-2">
            {/* <button className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600" onClick={(e) => { e.stopPropagation(); setEditModalOpen(false); }}>Cancel</button> */}
            <button
              type="submit"
              className="hidden md:block bg-[#1891c9] p-2 rounded-xl items-center font-semibold text-white cursor-pointer  mr-4 hover:bg-[#dfb3c1]"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AirDropModal