
import "flowbite";
import { TbLayoutDashboard } from "react-icons/tb";
import { SiMarketo } from "react-icons/si";
import { BsShop } from "react-icons/bs";
import { AiOutlineClose, AiOutlineSetting } from "react-icons/ai";
import { CgMenuRound } from "react-icons/cg";

import { Tooltip } from "react-tooltip";
import {MdCollections} from 'react-icons/md'
import React, { useContext, useState } from "react";
import { Link,useNavigate } from 'react-router-dom'
import {InstanceContext} from '../../../context/InstanceContext'

const Sidebar = () => {
      // useContext
      const {settoken}=useContext(InstanceContext);
      const navigate=useNavigate();
       // log out 
    const logout=()=>{
      localStorage.removeItem('tokenId');
      settoken("")
      navigate('/login');
  }
  const [active, setActive] = useState(false);
  const Handler = () => {
    setActive(!active);
  };
  return (
    <>
      <div className="h-screen hidden md:block lg:block xl:block   bg-[#363c3f] opacity-100 text-white w-[8%] py-12 fixed">
        <ul className="flex-col  lg:ml-8 xl:ml-8 ml-3">
          <li
            className="relative before:content-[attr(data-tip)]
                before:absolute
                font-bold
                before:px-1 before:py-4
                before:-left-20 before:top-7
                before:w-max before:max-w-xs
                before:translate-x-1/2 before:translate-y-1/3
                before:bg-gray-100 before:text-black
                before:rounded-md before:opacity-0
                before:transition-all
                after:absolute
                after:-right-full after:
               text-xl
                after:opacity-0
                after:transition-full
                hover:before:opacity-100 hover:after:opacity-100
                "
            data-tip="DASHBOARD"
          >
         <Link to='/dashboard'><TbLayoutDashboard className="h-10 w-10 cursor-pointer" /></Link>
          </li>
          <li
            className="py-20
          relative before:content-[attr(data-tip)]
          before:absolute
          font-bold
          before:px-3 before:py-4
          before:-left-24 before:top-28
          before:w-max before:max-w-xs
          before:translate-x-1/2 before:translate-y-1/3
          before:bg-gray-100 before:text-black
          before:rounded-md before:opacity-0
          before:transition-all
          after:absolute
          after:-right-full after:
         text-xl
          after:opacity-0
          after:transition-full
          hover:before:opacity-100 hover:after:opacity-100
          "
            data-tip="MARKETPLACE"
          >
            <Link to="/marketplace">
              <SiMarketo className="h-10 w-10 cursor-pointer" />
            </Link>
          </li>
          <li
            className="
          relative before:content-[attr(data-tip)]
          before:absolute
          font-bold
          before:px-3 before:py-4
          before:-left-12 before:top-7
          before:w-max before:max-w-xs
          before:translate-x-1/2 before:translate-y-1/3
          before:bg-gray-100 before:text-black
          before:rounded-md before:opacity-0
          before:transition-all
          after:absolute
          after:-right-full after:
          text-xl
          after:opacity-0
          after:transition-full
          hover:before:opacity-100 hover:after:opacity-100
          "
            data-tip="SHOP"
          >
            <Link to='/'>
            <BsShop className="h-10 w-10 cursor-pointer" />
            </Link>
          </li>
          <li
            className="
          relative before:content-[attr(data-tip)]
          before:absolute
          font-bold
          before:px-3 before:py-4
          before:-left-12 before:top-7
          before:w-max before:max-w-xs
          before:translate-x-1/2 before:translate-y-1/3
          before:bg-gray-100 before:text-black
          before:rounded-md before:opacity-0
          before:transition-all
          after:absolute
          after:-right-full after:
          text-xl
          after:opacity-0
          after:transition-full
          hover:before:opacity-100 hover:after:opacity-100
          "
            data-tip="Collection"
          >
            <Link to='/collection'>
            <MdCollections className="h-10 w-10 cursor-pointer my-20" />
            </Link>
          </li>
          <li
            className="
          
          relative before:content-[attr(data-tip)]
          before:absolute
          font-bold
          before:px-3 before:py-4
          before:-left-16 before:top-7
          before:w-max before:max-w-xs
          before:translate-x-1/2 before:translate-y-1/3
          before:bg-gray-100 before:text-black
          before:rounded-md before:opacity-0
          before:transition-all
          after:absolute
          after:-right-full after:
         text-xl
          after:opacity-0
          after:transition-full
          hover:before:opacity-100 hover:after:opacity-100
          "
            data-tip="SETTING"
          >
            <Link to="/setting">
              <AiOutlineSetting className="h-10 w-10 cursor-pointer my-20" />
            </Link>
          </li>
        </ul>
      </div>
      {/* responsive */}
      <div onClick={Handler} className="block md:hidden  pt-12 mx-4 ">
        {active ? (
          <AiOutlineClose size={30} className="text-white" />
        ) : (
          <div className="bg-gray-600  rounded-3xl h-12 w-12 cursor-pointer flex justify-center items-center ">
            <CgMenuRound className="text-white h-10 w-10" />
          </div>
        )}
      </div>

      <div
        className={
          active
            ? "md:hidden  flex flex-col  right-1/3 inset-0 fixed  z-50  text-white  bg-[#2A2C2E] "
            : " hidden"
        }
      >
        <ul>
          <li className="flex justify-end p-3">
            {active ? (
              <AiOutlineClose
                onClick={Handler}
                size={30}
                className="text-white font-extrabold"
              />
            ) : (
              ""
            )}
          </li>
          <li className="bg-gray-300  w-full h-[1px]"></li>
          <div className="flex ">
            <div className="bg-[#222326]  w-[70%] p-4 flex justify-center ">
              <button className="  bg-[#8f002d] p-2 rounded-xl items-center font-extrabold text-white cursor-pointer  mr-4 hover:bg-blue-900">
                {" "}
              <Link to='/register'>  Sign up{" "} </Link>
              </button>
            </div>
            <div className="bg-[#2f2f33] w-[30%] flex justify-center ">
              <button className="   p-2 rounded-xl items-center font-extrabold text-white cursor-pointer hover:underline mr-4 " onClick={logout}>
                {" "}
               log Out
              </button>
            </div>
          </div>
          {/* <li className="bg-gray-300 mt-3 w-full h-[1px]"></li> */}
          <li className="flex  px-4 py-12 items-center  text-sm cursor-pointer ">
            {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
            <TbLayoutDashboard className="h-8 w-8  cursor-pointer" />
            <p className="text-lg font-semibold px-4">
            <Link to='/dashboard'>  DASHBOARD </Link>
              </p>
          </li>
          <li className="flex  px-4 py-12 items-center  text-sm cursor-pointer ">
            {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
            <SiMarketo className="h-8 w-8  cursor-pointer" />
            <p className="text-lg font-semibold px-4">
            <Link to='/marketplace'>  MARKETPLACE </Link>
              </p>
          </li>
          <li className="flex  px-4 py-12 items-center  text-sm cursor-pointer ">
            {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
            <BsShop className="h-8 w-8  cursor-pointer" />
            <p className="text-lg font-semibold px-4">
            <Link to='/'>Shop</Link>
              </p>
          </li>
          <li className="flex  px-4 py-12 items-center  text-sm cursor-pointer ">
            {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
            <MdCollections className="h-8 w-8  cursor-pointer" />
            <p className="text-lg font-semibold px-4">
            <Link to='/collection'>Collection</Link>
              </p>
          </li>
          
          <li className="flex  px-4 py-12 items-center  text-sm cursor-pointer ">
            {/* <FaYinYang className="w-4 inline-flex h-4 mr-1 " /> */}
            <AiOutlineSetting className="h-8 w-8 cursor-pointer" />
            <p className="text-lg font-semibold px-4">
             <Link to="/setting"> SETTINGS </Link>
              </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
