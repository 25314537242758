import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/mythical_m_logo.png";
import { BsArrowRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { InstanceContext } from "../context/InstanceContext";
import { AuthContext } from "../context/AuthContext";
import metamasklogo from '../assets/metamask.png'
import { toast } from "react-toastify";
const Login = () => {
  // usestate
  const[checked,setChecked]=useState(false);
  //usenavigation
  const navigate=useNavigate();
  const navigateHandler=()=>{
    navigate('/register');
  }
  // usestate
  const[loginForm,setLogin]=useState({
    password:"",
  });
  // handle login input 
  const FormHandler=(e)=>{
setLogin({
  ...loginForm,
  [e.target.name]:e.target.value,
});
  }
  // useContext
  const {loginUser,token}=useContext(InstanceContext);
  const {address,connectWallet}=useContext(AuthContext);
  // login function
  const loginfunc=()=>{
    if(checked)
    {
    try{
      if(loginForm.password=='')
      {
        toast.warn("Enter Password!!!");
      }
      else{
    loginUser(loginForm);
      }
    }
    catch(error){
      console.log(error.message)
    }
  }else{
    toast.warn("Check the condition")
  }
}


  // useEffect
  useEffect(()=>{
    if(token!="")
    {
      navigate('/');
console.log("tokennn:",token);

    }
  },[token]);
  // handleChecked
  const handleChecked=()=>{
    setChecked(!checked);
  }
  return (
    <>
      <div className="h-screen w-full items-center  flex justify-center text-white bg-[#222326]">
        <div className="bg-[#16181a] m-4  ">
          <div className="p-4">
            <img src={logo} className="w-20" />
          </div>
          <div className="p-4 flex-col">
            <p className="text-4xl font-bold">Log in to Mythical account</p>
            <p className="text-lg font-bold pt-1">
              New to Mythical?{" "}
              <span onClick={navigateHandler} className="text-[#E3C691] underline cursor-pointer">
                {" "}
                Create an account.
              </span>
            </p>
          </div>
          <div className="flex-col p-4">
            <p className="text-[#98B8CD]">MetaMask Address</p>
            <input
              className="p-2 bg-[#121619] w-full border-white"
              type="text"
              value={address}
              disabled
            />
          </div>
          <div className="flex-col p-4">
            <p className="text-[#98B8CD]">Password</p>
            <input
              className="p-2 bg-[#121619] w-full border-white"
              type="password"
              name="password"
              value={loginForm.password}
              onChange={FormHandler}
              placeholder="enter password"
            />
          </div>
          <div className="flex p-4 items-center">
            <input className=" bg-[#fff]  border-white " type="checkbox" onChange={handleChecked} />
            <p className="text-white pl-2 ">
              Keep this device logged in for 30 days.
            </p>
          </div>
          <div className="flex p-4 items-center">
            {
            address?
            <>
            <button className="text-black p-2  bg-[#947C45] hover:bg-[#816c39]" type="submit" onClick={loginfunc}>
              Log in
              <BsArrowRight className="inline-flex ml-2 w-5 h-5" />
            </button>
            </>
            :
            <>
            <button className="text-black p-2  bg-white hover:bg-[#816c39] " type="submit" onClick={()=>{connectWallet()}} >
              connect to wallet
              <img src={metamasklogo} className="inline-flex ml-2 w-5 h-5"/>
            </button>
            </>
}
            <p className=" p-2 cursor-pointer text-[#947C45] hover:bg-[#343A3F]">
            <Link to='/forgot'> Forgot password?</Link>
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Login;
