import React, { useEffect, useContext } from "react";
import Landing from "./Pages/Landing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MarketPlace from "./Pages/marketplace/MarketPlace";
import Setting from "./Pages/marketplace/Setting";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Forgotpassword from "./Pages/Forgotpassword";
import Dashboard from "./Pages/marketplace/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mint from "./Pages/Mint";
import { AuthContext } from "./context/AuthContext";
import Cart from "./Pages/Cart";
import Section from "./components/Section";
import Detail from "./Pages/Detail";
import Protected from "./components/Protected";
import Collection from "./Pages/marketplace/Collection";
const App = () => {
  const { connectWallet } = useContext(AuthContext);

  useEffect(() => {
    connectWallet();
  }, [0]);
  return (
    <>
      <BrowserRouter>
        <ToastContainer theme="dark" />
        <Routes>
          <Route path="/" element={<Protected Component={Landing}/>}/>
          {/* <Route path="/landing" element={<Landing />} /> */}

          <Route path="/mint" element={<Protected Component={Mint}/>} />
          <Route path="/detail/:image/:Price/:Name/:Description/:endtime" element={<Protected Component={Detail}/>}/>
          <Route path="/marketplace" element={<Protected Component={MarketPlace}/>} />
          <Route path="/setting" element={<Protected Component={Setting}/>}/>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot" element={<Forgotpassword />} />
          <Route path="/dashboard" element={<Protected Component={Dashboard}/>} />
          <Route path="/collection" element={<Protected Component={Collection}/>}/>
          {/* <Route path="/cartItems" element={<Protected Component={Cart}/>} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
