import React from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

const Setting = () => {
  return (
    <>
      <div className=" flex flex-col  w-full bg-[#000105] h-screen pb-8 ">
        {/* sidebar */}
        <Sidebar />
        {/* navbar */}
        <Navbar />
      </div>
      {/* Footer */}
      <Footer />
    </>
  );
};
export default Setting;
