import React from 'react'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import pic1 from '../../assets/Left.png'
import Footer from './components/Footer'

const Collection = () => {
  return (
   <>
    <div className=" flex flex-col  w-full bg-[#000105] h-auto pb-8 ">
        {/* sidebar */}
        <Sidebar />
        {/* navbar */}
        <Navbar />
        <div className=" mt-16 md:block hidden ml-[10%] w-[90%]    ">
          <h1 className=" font-extrabold text-7xl text-white">MY COLLECTIONS</h1>
        </div>
        <div className="  h-auto  mt-2 pb-16 ml-[10%] w-[90%]   ">
            <div className='"lg:grid-cols-3 xl:grid-cols-3 justify-center  grid sm:grid-cols-1 md:grid-cols-2   gap-y-8 lg:pl-16 xl:pl-16  md:pl-4 mt-8'>
        {/* card */}
        <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic1} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>
              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic1} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>

              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic1} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>

              <div className="  lg:w-[350px] xl:w-[350px] md:w-[270px] w-[300px] bg-[#1A232F] p-6 hover:bg-[#2a2d38] cursor-pointer">
                <div className="flex overflow-hidden  justify-center">
                  <img className="hover:scale-110 h-[230px]  " src={pic1} />
                </div>
                <div className="text-white pt-2 ">
                  <p className="font-bold">Adhara's Helm</p>
                </div>
                <div className="text-white inline-flex pt-2 ">
                  <p className="font-bold text-gray-400 pr-2">Buy from</p>
                  <p className="font-bold ">$5.49</p>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="flex flex-col">
                    <p className="font-bold text-xl ">SEASON 1</p>
                    <p className="font-bold text-lg text-gray-400">SEASON</p>
                  </div>
                  <div className="flex flex-col pt-2">
                    <p className="font-bold text-xl ">117</p>
                    <p className="font-bold text-gray-400 text-lg">MINTED</p>
                  </div>
                </div>
                <div className="text-white flex justify-between pt-2">
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 font-bold bg-white text-black">
                      Buy
                    </button>
                  </div>
                  <div className="w-[150px]">
                    <button className="p-2 border-2 w-full border-gray-400 bg-gray-700 text-gray-400">
                      Sell
                    </button>
                  </div>
                </div>
              </div>


</div>
</div>
      </div>
      <Footer/>
   </>
  )
}

export default Collection