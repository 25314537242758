import { createContext,useContext,useState } from "react"
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";
const InstanceContext=createContext();
const InstanceProvider = ({children}) => {
  // use state 
  const[token,settoken]=useState('');
  // useCntext
  const {address}=useContext(AuthContext);
    // login user 
    const loginUser=(data)=>{
        try{
            
            console.log("Data sign in  from InstanceContext:",data);
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`,{
              ...data,
              address:address
            }).then(resp=>{
              // console.log("response of register user:",resp.data.token)
              toast.info(resp.data.message);
              localStorage.setItem("tokenId", resp.data.token)
             settoken(resp['data']['token']);

            })
            .catch(err=>{
              console.log(err)
            })
        }catch(err){
            console.log(err.message)
        }
    }
    // Register user 
    const registerUser=(data)=>{
      try{
        console.log("Data signup from InstanceContext:",data);
        console.log(process.env.REACT_APP_BACKEND_URL);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/SignUp`,{
          ...data,
          address:address
        }).then(resp=>{
          // console.log("response of register user:",resp.data.message)
          toast.info(resp.data.message);
        })
        .catch(err=>{
          console.log(err)
        })
      }catch(error){
        console.log(error.message);
      }
    }
  return (
  <InstanceContext.Provider value={{loginUser,registerUser,token,settoken}}>
    {children}
  </InstanceContext.Provider>
  )
}

export {InstanceContext,InstanceProvider}