import React from 'react'
import Footer from '../components/Footer'
import MenuCards from '../components/MenuCards'
import Navbar from '../components/Navbar'
import Section from '../components/Section'
import MysteryBox from '../components/MysteryBox'


const Landing = () => {
  return (
    <>
      <Navbar />
      <Section />
      {/* <MysteryBox/> */}
      <MenuCards />
      <Footer />
    </>
  );
}

export default Landing