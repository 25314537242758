import React from "react";
import sec_poster from "../assets/sec-poster.png";
import sec_logo from "../assets/sec-logo.png";
import discover from "../assets/discover.png";
import master from "../assets/mastercard.png";
import american from "../assets/american.png";
import { CiCircleQuestion } from "react-icons/ci";
import visa from "../assets/visa.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TiArrowForwardOutline } from "react-icons/ti";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Detail = () => {
    const { image, Price, Name, Description, endtime } = useParams();
  return (
    <>
      <Navbar />

      <div className=" w-full h-auto bg-gradient-to-tl from-[#2f1f56] to-[#3E0049]  pb-16">
        <div className="flex pt-8 mx-4  lg:mx-16 xl:mx-16 text-white">
          <h1 className=" font-bold cursor-pointer ">The Shop </h1>{" "}
          <p className="text-gray-300 "> / {Name} </p>
        </div>

        <div className="flex w-full  p-4 lg:p-0  xl:p-0    h-auto  lg:w-[90%]  rounded-xl mx-auto overflow-hidden">
          <div className="  w-full h-auto pb-10 bg-[#0C6291] rounded-xl  bg-opacity-90 text-white">
            <div className="p-2 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 ">
              {/* card */}
              <div className="  flex  justify-center">
                <img
                  className="w-auto h-[350px]"
                  src={`${process.env.REACT_APP_GATEWAY_MORALIES_URL}/${image}`}
                  alt={Name}
                />
              </div>
              <div className="py-6 ml-2">
                <div className="flex flex-row justify-between">
                  <div className="flex-row items-center justify-center p-2   bg-white  rounded-2xl text-black ">
                    <p className="font-bold text-sm">
                      <AiOutlineClockCircle className="text-red-700 inline-flex " />{" "}
                      Ends in <span className="text-red-700">{endtime}</span>
                    </p>
                  </div>
                  <div className="mr-4 cursor-pointer">
                    <TiArrowForwardOutline className="text-white h-5 w-5 " />
                  </div>
                </div>
                <div className="flex flex-row justify-between ">
                  <h1 className="font-bold text-3xl my-4">{Name}</h1>
                  <div className="w-[50px] h-[] my-4 mr-4">
                    <img className="text-white" src={sec_logo} />{" "}
                  </div>
                </div>
                <p className="">{Description}</p>
                <div className="flex flex-row mt-4">
                  <h1 className="font-extrabold text-3xl">ETH {Price}</h1>
                  <p className="font-bold text-lg mx-1 my-3"></p>
                </div>
                <div className="flex lg:flex-row md:flex-row xl:flex-row flex-col   justify-between ">
                  <button
                    className="p-5 mt-3 w-full md:w-[40%] xl:w-[25%] lg:w-[40%]  bg-[#3E0049] hover:bg-[#10475f] items-center justify-center text-xl font-extrabold rounded-xl"
                    onClick={async () => {
                      const respo = await axios.post(
                        `${process.env.REACT_APP_BACKEND_URL}/AddToCart`,
                        { Name, image, Price }
                      );
                      console.log("Get API response:", respo.data.message);
                      toast.success(respo.data.message);
                    }}
                  >
                    Buy Now
                  </button>

                  <ul className="flex   pt-6 ">
                    <li>
                      <img
                        className="w-[60px]  h-[40px] text-white bg-[#fff] rounded-lg mr-3 cursor-pointer "
                        src={visa}
                      />
                    </li>
                    <li>
                      <img
                        className="w-[60px]  h-[40px] text-white bg-[#fff] rounded-lg mr-3 cursor-pointer "
                        src={discover}
                      />
                    </li>
                    <li>
                      <img
                        className="w-[60px]  h-[40px] text-white bg-[#fff] rounded-lg mr-3 cursor-pointer "
                        src={master}
                      />
                    </li>
                    <li className="mr-3">
                      <img
                        className="w-[60px]  h-[40px] text-white bg-[#20B7F1] rounded-lg cursor-pointer "
                        src={american}
                      />
                    </li>
                  </ul>
                </div>
                <div className="flex pt-4 ">
                  <p className="text-xl ">
                    {/* Purchase Limit: 4{" "}
                    <CiCircleQuestion className="inline-flex hover:text-black rounded-xl hover:bg-white " /> */}
                  </p>
                  <p className="hidden text-sm text-white " id="popUpSMS">
                    Max qualities are limited per order to remain fair to all
                    Joat World collectors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Detail;
