import React from 'react'
import logo from "../assets/mythical_m_logo.png";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import {BiMailSend} from 'react-icons/bi';
const Forgotpassword = () => {
    const navigate=useNavigate();
    const navigateHandler=()=>{
      navigate('/register');
    }
  return (
   <>
    <div className="h-screen w-full items-center  flex justify-center text-white bg-[#222326]">
        <div className="bg-[#16181a] m-4  ">
          <div className="p-4">
            <img src={logo} className="w-20" />
          </div>
          <div className="p-4 flex-col">
            <p className="text-4xl font-bold">Forgot password</p>
            <p className="text-lg font-bold pt-1">
            Type in your email address in the form below to reset your password.
            </p>
          </div>
          <div className="flex-col p-4">
            <p className="text-[#98B8CD]">Email</p>
            <input
              className="p-2 bg-[#121619] w-full border-white"
              type="email"
            />
          </div>
        
         
          <div className="flex p-4 items-center">
            <button className="text-white p-2  bg-[#343A3F]">
             Send email with a link 
              <BiMailSend className="inline-flex ml-2 w-5 h-5" />
            </button>
           
          </div>
        </div>
      </div>
   </>
  )
}

export default Forgotpassword