import React from 'react'
import mthical from '../../../assets/mythical_m_logo.png';
import thirdindlogo from '../../../assets/thirdkind_logo.png';
import {BiMessageAltDots} from 'react-icons/bi';
import {SlSocialReddit} from 'react-icons/sl';
import {TfiTwitter} from 'react-icons/tfi';
import {AiOutlineFacebook,AiOutlineInstagram,AiOutlineYoutube} from 'react-icons/ai'
const Filters = () => {
  return (
    <>
      <div className='bg-[#20232C] w-auto lg:ml-20 xl:ml-20 md:ml-16  h-auto pb-16'>
        <div className='flex-col flex sm:flex-row md:flex-row lg:flex-row xl:flex-row  lg:justify-between md:justify-between xl:justify-between  '>
          <div className='flex-col px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-16 text-white'>
          <h2 className='  font-extrabold text-2xl'>MARKETPLACE</h2>
        <p className='text-sm py-4'>© 2022 Mythical Inc. Mythical Games & Joat World are <br></br> trademarks of Mythical Inc. All Rights Reserved</p>
        <p className='text-sm pt-4'>Mythical Games</p>
        <p className='text-sm '>P.O. Box 57137</p>
        <p className='text-sm '>Sherman Oaks, CA 91413</p>
         
          </div>
          <div className=' text-white px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 xl:pt-20 md:pt-20 pt-4'>
            <h2 className='  font-extrabold'>DISCOVER</h2>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>play now</p>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>FAQ</p>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>Support</p>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>Privacy Notice</p>

          </div>
          <div className=' text-white px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-4 '>
            <h2 className='  font-extrabold'>TERMS OF USE</h2>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>Mythical Games</p>
            <p className='cursor-pointer mt-1 underline text-[#B99B51]'>Joat World Block Party</p>
          </div>
        </div>

        {/*  */}
        <div className=''>
          <ul className='flex justify-evenly lg:pt-20 md:pt-20 pt-4'>
            {/* <li className='cursor-pointer '  ><BiMessageAltDots className='text-white w-8 h-8'/></li> */}
            <li className='cursor-pointer '  > <a href='https://discord.com/invite/playJoat World'><SlSocialReddit className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer '  ><a href='https://twitter.com/PlayJoat World'><TfiTwitter className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer '  ><a href='https://www.facebook.com/playJoat World/'><AiOutlineFacebook className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer '  ><a href='https://www.instagram.com/playJoat World/'><AiOutlineInstagram className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer '  ><a href='https://www.youtube.com/playJoat World'><AiOutlineYoutube className='text-white w-8 h-8'/></a></li>

          </ul>
        
         
        </div>
   </div>
    </>
  )
}

export default Filters