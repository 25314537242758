import React from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import {ImArrowRight} from 'react-icons/im'
import {BsFillClockFill} from 'react-icons/bs'
import logo from '../../assets/blankos.jpg'
import { Scrollbars } from 'react-custom-scrollbars';

const Dashboard = () => {
  return (
    <>
      <div className=" flex flex-col  w-full bg-[#000105] h-auto pb-8 ">
        <Sidebar />
        <Navbar />
        <div className=" mt-16 md:block hidden ml-[10%] w-[90%]    ">
          <h1 className=" font-extrabold text-7xl text-white">DASHBOARD</h1>
        </div>
        <div className="flex items-center lg:ml-[10%] md:ml-[10%] xl:ml-[10%] ml-2 w-[90%]   pr-4 mt-8">
          <div
            className={`bg-[#ec566a] hover:bg-[#FB6A7E]  p-2  font-bold text-white cursor-pointer`}
          >
            Refresh
          </div>
          <div className="px-2 ">
            <p className="text-[#CCC3AD] font-semibold">
              LAST UPDATE 10:04:59 AM
            </p>
          </div>
        </div>
        <div className=" hidden lg:flex items-center justify-evenly lg:ml-[10%] md:ml-[10%] xl:ml-[10%] ml-2  w-[90%] text-white  pr-4 mt-8">
        <p className="text-3xl font-bold">Latest SALES</p>
        <p className="text-3xl font-bold">Latest LISTING</p>
</div>
<div className="block lg:hidden lg:ml-[10%] md:ml-[10%] xl:ml-[10%] ml-2  w-[90%] text-white  pr-4 mt-8">
<p className="text-3xl font-bold">Latest SALES</p>
</div>
      <div className="  flex lg:flex-row md:flex-col flex-col lg:ml-[10%] md:ml-[10%] xl:ml-[10%] ml-2   md:w-[90%]  lg:w-[88%] xl:w-[88%] w-[98%]  h-auto pr-4 mt-8 mb-10 ">
      
      <div className="flex-col  lg:w-[50%] xl:w-[50%] w-full h-24 mr-4 ">
      <Scrollbars   style={{  }}>
        <div className="flex  items-center w-full h-full  bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>

         
          <div className="flex  items-center w-full h-full my-4 bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>
        
          <div className="flex  items-center w-full h-full bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>
          </Scrollbars>
        </div>
    

        <div className="block lg:hidden     text-white  pr-4 mt-8">
        <p className="text-3xl font-bold">Latest LISTING</p>
</div>
        
        {/* listing */}

        <div className="lg:w-[50%] xl:w-[50%] w-full h-24 lg:mt-0 xl:mt-0 mt-4 ">
      <Scrollbars   style={{   }}>

      <div className="flex  items-center w-full h-full bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>
         
          <div className="flex  items-center w-full h-full my-4 bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>
          
          <div className="flex  items-center w-full h-full bg-[#272A35] ">
            <div className="flex border-r-2 border-[#3D404A] h-full items-center w-[50%]">
            <div className="w-12 h-12  xl:ml-2 lg:ml-2 md:ml-2  rounded-3xl bg-slate-300 ">
              {/* <img src={logo} className=''/> */}
            </div>
            <div className="pl-1 md:pl-2 lg:pl-2 xl:pl-2">
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">Manila Killa</p>
            <p className="font-bold text-white lg:text-base md:text-base xl:text-base text-sm">#44 <span className="text-[#677A86]">/466</span></p>
            </div>
            </div>
            <div className="flex justify-between  items-center lg:ml-4 md:ml-4 xl:ml-4 ml-1 w-full h-full">
              <p className="font-bold text-[#cc9b5e]">78635..</p>
              <p className=""><ImArrowRight className="text-[#7D7F86] w-full h-6"/></p>
              <p className="font-bold text-[#cc9b5e]">C8562..</p>
              <div className="flex-col items-center">
              <p className="text-white font-bold">$18.00</p>
              <p className="text-[#7D7F86]  font-bold xl:mr-2 lg:mr-2 md:mr-2 mr-1"><BsFillClockFill className='text-[#7D7F86] inline-flex mr-1'/>2 hours ago</p>

              </div>
            </div>
          </div>
          </Scrollbars>
        </div>
          
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
