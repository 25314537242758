import React from 'react';
import mthical from '../assets/mythical_m_logo.png';
import thirdindlogo from '../assets/thirdkind_logo.png';
import {BiMessageAltDots} from 'react-icons/bi';
import {SlSocialReddit} from 'react-icons/sl';
import {TfiTwitter} from 'react-icons/tfi';
import {AiOutlineFacebook,AiOutlineInstagram,AiOutlineYoutube} from 'react-icons/ai'

const Footer = () => {
  return (
    <div className='bg-[#141414] w-full h-auto pb-16'>
        <div className='flex-col flex sm:flex-row md:flex-row lg:flex-row xl:flex-row  lg:justify-between md:justify-between xl:justify-between  '>
          <div className='inline-flex px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-16 '>
            <img className='cursor-pointer h-28' src={mthical} />
            <img className='mx-8 cursor-pointer h-28' src={thirdindlogo} />
          </div>
          <div className=' text-white px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-4'>
            <h2 className='  font-extrabold'>DISCOVER</h2>
            <p className='cursor-pointer mt-1 underline'>play now</p>
            <p className='cursor-pointer mt-1 underline'>FAQ</p>
            <p className='cursor-pointer mt-1 underline'>Support</p>
            <p className='cursor-pointer mt-1 underline'>Privacy Notice</p>

          </div>
          <div className=' text-white px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-4 '>
            <h2 className='  font-extrabold'>TERMS OF USE</h2>
            <p className='cursor-pointer mt-1 underline'>Mythical Games</p>
            <p className='cursor-pointer mt-1 underline'>Joat World Block Party</p>
          </div>
        </div>

        {/*  */}
        <div className='flex-col flex sm:flex-row md:flex-row lg:flex-row xl:flex-row  lg:justify-between md:justify-between xl:justify-between  '>
          <ul className='inline-flex px-3 sm:lg:px-16 md:px-16 xl:px-16 lg:px-16 lg:pt-20 md:pt-20 pt-4'>
            <li className='cursor-pointer mr-2'  ><a href='https://discord.com/invite/playJoat World'><BiMessageAltDots className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer mr-2'  ><a href='https://www.reddit.com/r/PlayJoat World'><SlSocialReddit className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer mr-2'  ><a href='https://twitter.com/PlayJoat World'><TfiTwitter className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer mr-2'  ><a href='https://www.facebook.com/playJoat World/'><AiOutlineFacebook className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer mr-2'  ><a href='https://www.instagram.com/playJoat World/'><AiOutlineInstagram className='text-white w-8 h-8'/></a></li>
            <li className='cursor-pointer mr-2'  ><a href='https://www.youtube.com/playJoat World'><AiOutlineYoutube className='text-white w-8 h-8'/></a></li>

          </ul>
          <div className='px-3 sm:lg:px-0 md:px-0 xl:px-0 lg:px-0  pt-2 text-white pr-1 '>
           
            <p className='cursor-pointer mt-1  '>© 2022,Built with Joat World Party.<br></br> Joat World are trademarks of Mythical, Inc. All rights reserved.</p>
            <p className='cursor-pointer mt-1  '>This site is protected by reCAPTCHA and the Google <span className='underline'> Privacy Policy </span> and <span className='underline'> Terms of Service </span> apply.</p>

          </div>
         
        </div>
   </div>
  )
}

export default Footer