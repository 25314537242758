import React, { useState,useContext } from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ethers } from 'ethers';
import { ERC721ABI } from '../utils/ERC721ABI'
import { AuthContext } from '../context/AuthContext'
import { CONTRACT_PUBLIC_ADDRESS } from '../utils/ContractAddress'
const Mint = () => {
  // useContext
const{address}=useContext(AuthContext);
const {connectWallet} = useContext(AuthContext);
// useState
const[formInput,setformInput]=useState({
  name:"",
  price:"",
  description:"",
  quantity:""
});
const[istimemodel,setIstimemodel]=useState(false);
const[timeInput,setTimeinput]=useState({
  start:"",
  end:""
});
const [pdfFile, setFile] = useState(null);
// Form Handler
const FormHandler=(e)=>{
  setformInput({
    ...formInput,
    [e.target.name]:e.target.value,
  });
}
// time input handler
const TimeInputHandler=(e)=>{
  setTimeinput({
    ...timeInput,
    [e.target.name]:e.target.value,
  })
}

// Pop Model Handler 
const ModelHandler=()=>{
  setIstimemodel(!istimemodel);
}
const FileHandler = (e) => {
  console.log(e.target.files[0]);
  setFile(e.target.files[0]);
};

const sendFile= async (e)=>{
e.preventDefault();
connectWallet();
const {name,price,description}=formInput;
if(!name || !price || !description||!pdfFile)
{
   toast.error("Please fill all the feilds");
   console.log("contract address",process.env.REACT_APP_CONTRACT_ADDRESS);
  return;
}
try {
  // upload pdf file to ipfs 
  console.log("PINATA API KEY",process.env.REACT_APP_PINATA_API_KEY);
  console.log("PINATA API SECRET",process.env.REACT_APP_PINATA_API_SECRET);
  const formData = new FormData();
  formData.append("file", pdfFile);
  toast.promise(
    axios({
      method: "post",
      url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
      data: formData,
      headers: {
        pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
        pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
        "Content-Type": "multipart/form-data",
      },
    }).then((responce) => {
      
       handleCreateMetadata(
         responce?.data?.IpfsHash
      );
      console.log("response from image file :", responce.data.IpfsHash);
      //  APICall(responce.data.IpfsHash);

    }),
    {
      pending: "Uploading Data to IPFS",
      error: "Error while uploading PDF to IPFS",
    }
  );
} catch (error) {
  console.log(error);
  toast.error("Error while uploading File to IPFS");
}
const handleCreateMetadata = async (imgHash) => {
const metdata={
  name:name,
  price:price,
   imgURI:imgHash,
  description:description
};
console.log("MetaData:",metdata);
toast.promise(
  axios({
    method:'post',
    url:"https://api.pinata.cloud/pinning/pinJSONToIPFS",
    data:JSON.stringify(metdata),
    headers: {
      pinata_api_key: `${process.env.REACT_APP_PINATA_API_KEY}`,
      pinata_secret_api_key: `${process.env.REACT_APP_PINATA_API_SECRET}`,
      "Content-Type": "application/json",
    },
  })
  .then((res)=>{
    console.log('Response :',res.data.IpfsHash);

     mintItem(res.data.IpfsHash, imgHash);
     
  })
  .catch((error)=>{
    console.log("Error while uploading metaData on ipfs");
    toast.error("Error Uploading Metadata to IPFS: Metadata ");
  }),
  {
    pending: "Upload data to IPFS",
    success: "Uploaded Metadata",
    error: "Something went wrong!",
  }
)
}
}
const mintItem = async (metdataURI, imgHash) => {
  console.log("Mint function call");
  console.log("Contract address:", address);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  console.log("URI", metdataURI);
  console.log(
    "contract address:",
    `${process.env.REACT_APP_CONTRACT_PUBLIC_ADDRESS}`
  );
  const contract = new ethers.Contract(
    process.env.REACT_APP_CONTRACT_ADDRESS,
    ERC721ABI,
    signer
  );
  console.log("contract :", contract);
  console.log("MetaMask Adress from mint file :", address);
  toast.promise(
    contract.safeMint(address, metdataURI).then((transaction) => {
      toast.promise(
        transaction
          .wait()
          .then((tx) => {
            APICall(imgHash);
            console.log("Minting call response:", tx);
            toast.info(tx);
          })
          .catch((err) => {
            toast.error("Error in Minting Token:", err);
          }),
        {
          pending: "Minting in Process...",
          success: "Mint Successfully 👌",
          error: "Promise rejected 🤯",
        }
      );
    }),
    {
      pending: "Waiting to Sign Transaction...",
      success: "Transaction Signed... 👌",
      error: "Transaction Rejected 🤯",
    }
  );
};
const APICall=(ipfsHash)=>{
  console.log("ipfsHash:",ipfsHash);
  
  axios({
    method:'post',
    url:`${process.env.REACT_APP_BACKEND_URL}/StoreData`,
    data:{
      Name:formInput.name,
      Price:formInput.price,
      image:ipfsHash,
      Description:formInput.description,
      Address:address,
      StartTime: timeInput.start,
      EndTime: timeInput.end,
    }
    
  })
  .then((resp)=>{
    console.log("API response:",resp);
  })
  .catch((err)=>{
    console.log('Error from API',err.message);
  })
}
  return (
    <>
      <Navbar />

      <div className=" w-full h-screen bg-gradient-to-tl from-[#2f1f56] to-[#3E0049]  pb-16">
        <div className="flex w-full items-center justify-center pt-44  p-2 pb-0  lg:w-8/12  h-auto    rounded-xl mx-auto overflow-hidden">
         
          {/* <div className="  w-full h-auto pb-10   bg-[#9A7197] rounded-xl  bg-opacity-90 text-white"> */}
          <div className="  w-full h-auto p-10   bg-[#0C6291] rounded-xl  bg-opacity-90 text-white">
            {/* model */}
            <div
              className={` ${istimemodel ? "flex justify-center" : "hidden"} `}
            >
              <div className="absolute bg-[#0C6291]     w-72 h-auto pb-4">
                <div className="flex flex-col justify-start px-2 w-[100%]">
                  <lable className="pt-1 text-lg px">Start time</lable>
                  <input
                    className="pt-1 px-2 w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="datetime-local"
                    name="start"
                    value={timeInput.start}
                    onChange={TimeInputHandler}
                    placeholder="enter start time "
                  />
                </div>
                <div className="flex flex-col justify-start px-2 w-[100%]">
                  <lable className="pt-1 text-lg px">End time</lable>
                  <input
                    className="pt-1 px-2 w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="datetime-local"
                    name="end"
                    value={timeInput.end}
                    onChange={TimeInputHandler}
                    placeholder="end start time "
                  />
                </div>
                <div className="flex justify-end py-2 pr-2">
                  <button
                    className="text-white bg-transparent placeholder-white "
                    onClick={ModelHandler}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <form action="#" className="px-3" encType="multipart/form-data">
              <div className="flex  w-[100%]">
                <div className="flex flex-col justify-start w-[100%]">
                  <lable className="py-1 text-lg px">Name</lable>
                  <input
                    className="py-1 px-2 w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="text"
                    name="name"
                    value={formInput.name}
                    onChange={FormHandler}
                    placeholder="name"
                  />
                </div>
                <div className="flex flex-col justify-start ml-1 w-[100%]">
                  <lable className="py-1 text-lg px">Price</lable>
                  <input
                    className="py-1 px-2 w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="number"
                    name="price"
                    value={formInput.price}
                    onChange={FormHandler}
                    min="1"
                    placeholder=" price"
                  />
                </div>
              </div>

              <div className="flex w-[100%]">
                <div className="flex flex-col justify-start w-[100%]">
                  <lable className="py-[3px] text-lg px">Image</lable>
                  <input
                    className="py-[px] px-2 w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="file"
                    onChange={FileHandler}
                    placeholder=""
                  />
                </div>
                <div className="flex  flex-col justify-start ml-1 w-[100%]">
                  <lable className="py-[3px] text-lg px">Description</lable>
                  <input
                    className="    w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="text"
                    name="description"
                    value={formInput.description}
                    onChange={FormHandler}
                    placeholder="description"
                  />
                </div>
              </div>
              <div className="flex w-[100]">
                <div className="flex  flex-col justify-start ml-1 w-[100%]">
                  <lable className="py-[3px] text-lg px">Quantity</lable>
                  <input
                    className=" w-[100%] text-white bg-transparent placeholder-white border border-gray-400"
                    type="number"
                    name="quantity"
                    value={formInput.quantity}
                    onChange={FormHandler}
                    placeholder="quantity"
                  />
                </div>
                <div className="flex  flex-col justify-start ml-1 w-[100%]">
                  <lable className="py-[3px] text-lg px">
                    start & end time{" "}
                  </lable>
                  <div className="border border-gray-400 w-[100%] p-2">
                    <input
                      className=" w-  bg-transparent placeholder-white "
                      type="checkbox"
                      name="istimemodel"
                      value={istimemodel}
                      onChange={ModelHandler}
                      // placeholder='description'
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="m-auto flex w-[72%] rounded-lg justify-center hover:bg-[#0C6291]  bg-[#2f1f56]">
                  <button
                    className="text-lg font-semibold m-auto rounded-lg"
                    onClick={sendFile}
                  >
                    Mint
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Mint